import '../../css/pages/login.scss';
import BasePage from './base';

class LoginPage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new LoginPage();
